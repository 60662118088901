import { useContext } from "react"

import { authFormFields } from "./utils/authFormFields"
import { generateFormField } from "elements/Form/services/form"

import { AppContext } from "../../context/AppContext"
import { AuthContext } from "context/AuthContext"
import { EpharmacyContext } from "../Epharmacy/EpharmacyContext/EpharmacyContext"
import guidelines from "../../components/Epharmacy/utils/uploadGuidelines.json"
import validIDTypes from "../../components/Elements/Form/utils/validIDTypes.json"

const AuthForm = ({
  module,
  values,
  errors,
  submitCount,
  setMessage,
  setFieldValue,
  handleResendOTP,
  hasUploadedValidIDChecker,
  numberOfTimesHasUploadedID,
}) => {
  const { state, dispatch } = useContext(AppContext)
  const { authDispatch } = useContext(AuthContext)
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)
  let formFields = authFormFields(values, handleResendOTP)

  const changeBirthdayAttempt = () => {
    setMessage({})
    authDispatch({
      type: "SET_INCORRECT_BIRTHDAY_ATTEMPT",
      payload: false,
    })
  } 

  formFields = formFields.filter((formField) => {
    return formField.inclusions.includes(module)
  })

  // Remove Upload ID and ID Type fields from Auth fields if module is verify-birthday and user has already uploaded an ID
  if (module == "verify-birthday") {
    if (hasUploadedValidIDChecker) {
      formFields = formFields.filter((formField) => {
        return formField.type != "upload"
      })

      formFields = formFields.filter((formField) => {
        return formField.type != "select"
      })
    }
  }

  formFields = formFields.map((formField) => {
    if (!formField?.referenceAnswer) {
      return generateFormField({
        formField,
        values,
        formFields: formFields,
        errors,
        submitCount,
        setFieldValue,
        onChange: module == "verify-birthday" ? changeBirthdayAttempt : null,
        state: module == "verify-birthday" ? epharmacyState : state,
        dispatch: module == "verify-birthday" ? epharmacyDispatch : dispatch,
        hasUploadedValidIDChecker: hasUploadedValidIDChecker,
        numberOfTimesHasUploadedID: numberOfTimesHasUploadedID,
        type: "auth",
        options: validIDTypes,
        guidelines: guidelines,
      })
    }
    return null
  })

  return formFields
}

export default AuthForm
