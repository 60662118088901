import firebase from "firebase"
import { navigate } from "gatsby"
import { updateUserDocument } from "../../Epharmacy/services/user"
import { isBrowser } from "../../../services/general"

export const getUserData = async ({ authUid }) => {
  const filteredUsers = await firebase
    .firestore()
    .collection("users")
    .where("authUid", "==", authUid)
    .get()
  let userData
  filteredUsers.forEach((user) => {
    userData = { ...user.data(), id: user.id }
  })

  return userData
}

export const getUserDataByMobileNumber = async ({ authUid, mobileNumber }) => {
  const filteredUsers = await firebase
    .firestore()
    .collection("users")
    .where("mobileNumber", "==", mobileNumber)
    .get()
  let userData
  filteredUsers.forEach((user) => {
    userData = { ...user.data(), id: user.id }
  })

  if (!userData) {
    return userData
  } else {
    await updateUserDocument({
      user: userData,
      authUid: authUid,
    })

    return userData
  }
}

export const getUserAddresses = async ({ addressesId }) => {
  let filteredAddress = await firebase
    .firestore()
    .collection("addresses")
    .doc(addressesId || " ")
    .get()
  if (filteredAddress.data())
    filteredAddress = {
      ...filteredAddress.data(),
      id: filteredAddress.id,
    }
  else filteredAddress = {}

  return filteredAddress
}

export const sendOTP = async ({ mobileNumber }) => {
  let recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    "recaptcha-container",
    {
      size: "invisible",
    }
  )

  return await firebase
    .auth()
    .signInWithPhoneNumber(mobileNumber, recaptchaVerifier)
}

export const checkIfMobileNumberAlreadyExists = async ({ mobileNumber }) => {
  const PROVIDERS = await firebase
    .auth()
    .fetchSignInMethodsForEmail(mobileNumber)
  return PROVIDERS.includes("password")
}

export const handleVerifyName = async ({
  values,
  callBack,
  setMessage,
  resetForm,
  errorCallback,
  locationState,
}) => {
  try {
    let hasMatchingName = false
    const { redirectTo } = locationState

    if (isBrowser()) {
      // console.log(values)
      if (
        values.lastName.replace(/ /g, "").toLowerCase() ===
        locationState.lastName.replace(/ /g, "").toLowerCase()
      ) {
        hasMatchingName = true
      }
      sessionStorage.setItem("hasVerifiedName", JSON.stringify(hasMatchingName))
    }

    resetForm()
    if (callBack) callBack()

    if (redirectTo && redirectTo !== "null" && hasMatchingName) {
      return navigate(`${redirectTo}`)
    } else {
      setMessage({
        type: "danger",
        content: {
          message: "The last name you have entered is incorrect.",
        },
      })
    }
  } catch (error) {
    if (errorCallback) errorCallback()
    setMessage({
      type: "danger",
      content: {
        code: error.code,
        message: error?.message,
      },
    })
  }
}
