import React, { useContext, useEffect, useState } from "react"
import { Formik, Form } from "formik"
import classNames from "classnames"
import { Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "layout"
import AuthForm from "./AuthForm"
import Button from "elements/Button"
import Message from "elements/Message"
import Container from "layout/Container"
import Notification from "../Layout/Notification"

import { AppContext } from "context/AppContext"
import { AuthContext } from "context/AuthContext"
import { EpharmacyContext } from "../Epharmacy/EpharmacyContext/EpharmacyContext"
import { handleAuthSubmit } from "./services/auth"
import { authValidationSchema } from "./utils/authValidationSchema"
import { navigate } from "gatsby-link"
import { getSignedInUser } from "./services/user"
import { isBrowser } from "../../services/general"

import styles from "./utils/updateMobileNumberComplete.module.scss"
import {
  GATSBY_ORDER_AS_GUEST_KEY,
  GATSBY_ORDER_AS_GUEST_VALUE,
  GATSBY_ORDER_AS_GUEST,
} from "gatsby-env-variables"
import { sendOTP } from "./services/signin"

const Auth = (props) => {
  const defaultVerifyIdentityMessage =
    "Please enter your birthday to confirm your account."
  const alternativeVerifyIdentityMessage =
    "To help us verify your identity, we will be requesting you to upload a valid ID and input your birthday."
  const data = useStaticQuery(graphql`
    {
      phone: file(relativePath: { eq: "icons/mh__2_confirm.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})
  const { dispatch } = useContext(AppContext)
  const { authState, authDispatch } = useContext(AuthContext)
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)

  let locationState = props?.location?.state
  let { pageContext, location } = props
  let { module, backPath } = pageContext

  if (module?.name === "verify-birthday") {
    pageContext.module.cta = authState?.incorrectBirthdayAttempt
      ? "Email MedGrocer"
      : "Confirm"
  }

  const handleSubmit = (values, { resetForm }) => {
    setMessage({})
    setLoading(true)

    if (module?.name == "verify-birthday") {
      epharmacyDispatch({
        type: "SAVE_ID_TYPE",
        payload: values["idType"]?.value,
      })
    }

    let payload = {
      values,
      resetForm,
      setMessage,
      setLoading,
      otpVerifier: authState?.otpVerifier || null,
      authDispatch,
      module: module?.name,
      locationState: props.location.state || locationState,
    }
    handleAuthSubmit({ payload, state: authState, dispatch, location })
  }

  // Gets locationState from session storage if there is nothing in location state
  if (isBrowser() && !locationState) {
    locationState = JSON.parse(sessionStorage.getItem("locationState"))
  }

  // Prevents locationState from going away if the page gets refreshed
  useEffect(() => {
    if (isBrowser()) {
      const existingLocationState = sessionStorage.getItem("locationState")
      if (!existingLocationState || existingLocationState === "null") {
        sessionStorage.setItem(
          "locationState",
          JSON.stringify(props.location.state)
        )
      }
    }
  }, [locationState])

  const handleOrderAsGuest = () => {
    if (isBrowser()) {
      window.sessionStorage.setItem(
        GATSBY_ORDER_AS_GUEST_KEY,
        GATSBY_ORDER_AS_GUEST_VALUE
      )
    }
    navigate("/epharmacy/order")
  }

  const handleResendOTP = async ({ mobileNumber }) => {
    setMessage(null)

    let tempOtpVerifier = await sendOTP({
      mobileNumber,
    })

    authDispatch({
      type: "SAVE_DETAILS",
      payload: {
        otpVerifier: tempOtpVerifier,
      },
    })

    let resendCount = authState?.otpResendCount - 1
    authDispatch({
      type: "SET_OTP_RESEND_COUNT",
      payload: resendCount,
    })
    setMessage({
      type: "success",
      content: `OTP has been resent to your mobile number. You have ${resendCount} resend attempts remaining.`,
    })
  }

  useEffect(() => {
    if (module?.name === "sign-in" && !!getSignedInUser()?.userData?.id)
      navigate("/")
    if (module?.name === "verify-otp" && !authState?.mobileNumber)
      navigate("/sign-in")
  }, [])

  let hasUploadedValidIDChecker = getSignedInUser()?.userData
    ? getSignedInUser()?.userData?.hasUploadedValidID
    : true
  let numberOfTimesHasUploadedID = getSignedInUser()?.userData
    ?.numberOfTimesHasUploadedID

  if (module.name == "verify-birthday") {
    module.subtitle = hasUploadedValidIDChecker
      ? defaultVerifyIdentityMessage
      : alternativeVerifyIdentityMessage
  }

  let verifyOTPpage = false
  if (module.name == "verify-otp") verifyOTPpage = true

  return (
    <Layout
      title={module?.title}
      subtitle={module?.subtitle}
      seoTitle={module?.title}
      isSignInPage={module?.isSignInPage}
      verifyOTPpage={verifyOTPpage}
    >
      <Container isCentered desktop={5} fullhd={5}>
        <Formik
          initialValues={{ ...authState }}
          validationSchema={authValidationSchema(module?.name)}
          onSubmit={handleSubmit}
        >
          {({ values, errors, submitCount, isVerifyPage, setFieldValue }) => (
            <Form>
              {module?.name === "update-number" ? (
                <Message color="warning">
                  <p className="is-size-6">
                    Already enrolled but forgot your mobile number? Request your
                    registered mobile ID through emailing us at{" "}
                    <a
                      href="mailto:pulsecare@medgrocer.com"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      pulsecare@medgrocer.com
                    </a>{" "}
                    and attaching a valid ID.
                  </p>
                </Message>
              ) : null}
              <AuthForm
                module={module?.name}
                values={values}
                errors={errors}
                submitCount={submitCount}
                setMessage={setMessage}
                setFieldValue={setFieldValue}
                handleResendOTP={handleResendOTP}
                hasUploadedValidIDChecker={hasUploadedValidIDChecker}
                numberOfTimesHasUploadedID={numberOfTimesHasUploadedID}
              />

              {module?.name === "verify-otp" ? (
                <p className="is-size-6 mb-1">
                  Mobile number did not receive an OTP?{" "}
                  <a
                    onClick={async () =>
                      await handleResendOTP({
                        mobileNumber: `+63${authState?.mobileNumber}`,
                      })
                    }
                  >
                    Resend OTP
                  </a>
                </p>
              ) : null}

              {module?.name === "sign-in" ? (
                <p className="is-size-6 mb-1">
                  If you want to change your mobile number, fill out the form{" "}
                  <Link to="/update-number">here.</Link>
                </p>
              ) : null}

              {message?.content && (
                <Message color={message?.type}>
                  <div>{message?.content?.message || message?.content}</div>
                </Message>
              )}

              {errors?.length > 0 ? (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              ) : null}

              {module?.name === "update-number-complete" ? (
                <div>
                  <div className={classNames("columns", styles["column"])}>
                    <div className="column is-4-desktop is-12-mobile">
                      <figure className={classNames("image mx-3")}>
                        <Img
                          fluid={data.phone.childImageSharp.fluid}
                          alt={`${module.name} Complete`}
                          className={styles["image"]}
                        />
                      </figure>
                    </div>

                    <div className="column">
                      <p className="is-size-5">
                        We will review your information and reach out to you
                        once we have updated our records.
                      </p>
                    </div>
                  </div>
                  <center className="is-flex is-justify-content-center mt-3">
                    <Link
                      to={backPath}
                      className="button is-primary is-medium mx-1"
                    >
                      {"Finish"}
                    </Link>
                  </center>
                </div>
              ) : null}

              {module?.name !== "update-number-complete" ? (
                <Button
                  type="submit"
                  color="primary"
                  isFullwidth
                  isLoading={loading}
                >
                  {module?.cta}
                </Button>
              ) : null}

              {module?.name === "sign-in" ? (
                <p className="is-size-5 mb-1 has-text-centered mt-1">
                  Enrolled but having trouble signing in?{" "}
                  <a type="button" onClick={handleOrderAsGuest}>
                    Order as Guest
                  </a>
                </p>
              ) : null}
            </Form>
          )}
        </Formik>
        {module.name === "sign-in" && GATSBY_ORDER_AS_GUEST === "enabled" && (
          <section></section>
        )}
      </Container>
      <div id="recaptcha-container"></div>
    </Layout>
  )
}

export default Auth
