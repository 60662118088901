import { Link } from "gatsby"
import React, { Fragment } from "react"

import { sendOTP } from "./signin"

export const parseAuthErrorMessage = (message, config) => {
  let { authDispatch, mobileNumber, setMessage } = config

  const handleResendOTP = async () => {
    let tempOtpVerifier = await sendOTP({
      mobileNumber,
    })
    authDispatch({
      type: "SAVE_DETAILS",
      payload: {
        otpVerifier: tempOtpVerifier,
      },
    })
    setMessage({
      type: "success",
      content: "OTP has been resent to your mobile number.",
    })
  }

  switch (message) {
    case "The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.":
      return (
        <Fragment>
          <div>
            The One-time PIN you have entered is incorrect.{" "}
            <span
              className="is-underlined is-clickable"
              onClick={handleResendOTP}
            >
              Resend OTP
            </span>
          </div>
        </Fragment>
      )
    case "The password is invalid or the user does not have a password.":
      return (
        <Fragment>
          The password you have entered is incorrect.{" "}
          <a href="/forgot-password">Forgot Password?</a>
        </Fragment>
      )
    default:
      return message
  }
}
