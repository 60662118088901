import * as Yup from "yup"

const REQUIRED_MESSAGE = "This field is required."

export const authValidationSchema = (module) => {
  let validationSchema = {}

  switch (module) {
    case "sign-in":
      validationSchema.mobileNumber = Yup.string()
        .min(
          10,
          "Please input a valid mobile number in this format: 9991234567"
        )
        .max(
          10,
          "Please input a valid mobile number in this format: 9991234567"
        )
        .matches(
          /^9[0-9]{9}$/,
          "Please input a valid mobile number in this format: 9991234567"
        )
        .required(REQUIRED_MESSAGE)
      break
    case "verify-otp":
      validationSchema.otp = Yup.string()
        .min(6, "Please input a valid six-digit OTP")
        .max(6, "Please input a valid six-digit OTP")
        .required(REQUIRED_MESSAGE)
      break
    case "update-number":
      validationSchema.oldMobileNumber = Yup.string()
        .required(REQUIRED_MESSAGE)
        .min(
          10,
          "Please input a valid mobile number in this format: 9991234567"
        )
        .max(
          10,
          "Please input a valid mobile number in this format: 9991234567"
        )
        .matches(
          /^9[0-9]{9}$/,
          "Please input a valid mobile number in this format: 9991234567"
        )
        .notOneOf(
          [Yup.ref("newMobileNumber")],
          "Old mobile number must not be the same as New mobile number."
        )
      validationSchema.newMobileNumber = Yup.string()
        .required(REQUIRED_MESSAGE)
        .min(
          10,
          "Please input a valid mobile number in this format: 9991234567"
        )
        .max(
          10,
          "Please input a valid mobile number in this format: 9991234567"
        )
        .matches(
          /^9[0-9]{9}$/,
          "Please input a valid mobile number in this format: 9991234567"
        )
        .notOneOf(
          [Yup.ref("oldMobileNumber")],
          "New mobile number must not be the same as Old mobile number."
        )
      validationSchema.idType = Yup.object().shape({
        value: Yup.string().required(REQUIRED_MESSAGE),
      })
      break
    case "verify-birthday":
      validationSchema.birthday = Yup.object().shape({
        month: Yup.object().shape({
          value: Yup.string().required(REQUIRED_MESSAGE),
        }),
        date: Yup.object().shape({
          value: Yup.string().required(REQUIRED_MESSAGE),
        }),
        year: Yup.object().shape({
          value: Yup.string().required(REQUIRED_MESSAGE),
        }),
      })
      break
  }

  return Yup.object().shape(validationSchema)
}
